import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import { lazyRoute, multiRoutes } from '@acre/utils'

import Root from '../Root'

const browserRouter = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        index: true,
        element: <Navigate to="ory-login" replace />,
      },

      {
        lazy: () => import('./lazy/LoginContainer'),
        children: [
          {
            path: 'ory-login',
            lazy: () => import('./lazy/OryLoginRoute'),
          },
          {
            path: 'client_login',
            element: <Navigate to="/" replace />,
          },
          { path: 'private_browsing', lazy: lazyRoute(() => import('./lazy/LoginContainerPrivateBrowsing')) },
          { path: 'login', lazy: lazyRoute(() => import('./lazy/LoginContainerLogin')) },
          { path: 'link_sent', lazy: lazyRoute(() => import('./lazy/LoginContainerResendMagicLink')) },
          { path: 'link_expired', lazy: lazyRoute(() => import('./lazy/LoginContainerSendMagicLinkExp')) },
          { path: 'confirm_user', lazy: lazyRoute(() => import('./lazy/LoginContainerUserDisambiguation')) },
          { path: 'confirm_case', lazy: lazyRoute(() => import('./lazy/LoginContainerCaseDisambiguation')) },
          { path: 'verify_idd', lazy: lazyRoute(() => import('./lazy/LoginContainerVerifyIdd')) },
          { path: 'login_error', lazy: lazyRoute(() => import('./lazy/LoginContainerLoginError')) },
          { path: 'denied_access', lazy: lazyRoute(() => import('./lazy/LoginContainerDeniedAccess')) },
          { path: 'client_error', lazy: lazyRoute(() => import('./lazy/LoginContainerClientError')) },
          { path: 'verification', lazy: lazyRoute(() => import('./lazy/OryVerification')) },
          { path: 'email_recovery', lazy: lazyRoute(() => import('./lazy/OryEmailVerification')) },
          { path: 'password', lazy: lazyRoute(() => import('./lazy/OryPassword')) },
          { path: 'settings', lazy: lazyRoute(() => import('./lazy/OryCreatePassword')) },
          { path: 'phone_selection', lazy: lazyRoute(() => import('./lazy/OryPhoneSelection')) },
          { path: 'no_invite', lazy: lazyRoute(() => import('./lazy/OryNoInvite')) },
          { path: 'flow-expired', lazy: lazyRoute(() => import('./lazy/FlowExpired')) },
        ],
      },

      /* Dashboard routes */
      ...multiRoutes({
        paths: ['dashboard', ':caseId/dashboard'],
        lazy: () => import('./lazy/Dashboard'),
      }),

      // Survey route
      { path: 'survey', lazy: lazyRoute(() => import('./lazy/Survey')) },

      /* Dashboard3 */
      { path: 'cases-dashboard', lazy: lazyRoute(() => import('./lazy/Dashboard')) },

      /* Schedule appointment routes */
      { path: 'schedule_appointment', lazy: lazyRoute(() => import('./lazy/ScheduleAppointment')) },
      { path: ':case_id/schedule_appointment', lazy: lazyRoute(() => import('./lazy/ScheduleAppointmentDirect')) },

      /* Messages */
      ...multiRoutes({
        paths: ['messages', 'messages/:userId'],
        lazy: lazyRoute(() => import('./lazy/SecureMessaging')),
      }),

      /* Messages V3 */
      ...multiRoutes({
        paths: ['chats', 'chats/:caseId'],
        lazy: lazyRoute(() => import('./lazy/SecureMessagingV3')),
      }),

      /* Documents */
      ...multiRoutes({
        paths: ['documents/:caseId', 'documents/:caseId/preview/:id', 'documents/preview/:id'],
        lazy: lazyRoute(() => import('./lazy/DocumentCaseView')),
      }),

      ...multiRoutes({
        paths: ['client_documents/:clientId', 'client_documents/:clientId/preview/:id'],
        lazy: lazyRoute(() => import('./lazy/DocumentClientView')),
      }),

      {
        path: 'documents',
        lazy: lazyRoute(() => import('./lazy/DocumentsDashboard')),
      },

      /* Documents Upload*/
      {
        path: 'document-upload',
        lazy: lazyRoute(() => import('./lazy/DocumentUpload')),
        children: [
          { path: 'income', lazy: lazyRoute(() => import('./lazy/DocumentUploadProofOfincome')) },
          { path: 'banking', lazy: lazyRoute(() => import('./lazy/DocumentUploadBankingStatement')) },
          { path: 'address', lazy: lazyRoute(() => import('./lazy/DocumentUploadProofOfAddress')) },
          { path: 'other', lazy: lazyRoute(() => import('./lazy/DocumentUploadAdditionalDocuments')) },
        ],
      },

      /* Case-specific Documents Upload */
      {
        path: ':caseId/document-upload',
        lazy: lazyRoute(() => import('./lazy/DocumentUpload3')),
        children: [
          { path: 'income', lazy: lazyRoute(() => import('./lazy/DocumentUploadProofOfincome3')) },
          { path: 'banking', lazy: lazyRoute(() => import('./lazy/DocumentUploadBankingStatement3')) },
          { path: 'address', lazy: lazyRoute(() => import('./lazy/DocumentUploadProofOfAddress3')) },
          { path: 'other', lazy: lazyRoute(() => import('./lazy/DocumentUploadAdditionalDocuments3')) },
        ],
      },

      /* Preview */
      { path: 'preview/:id', lazy: lazyRoute(() => import('./lazy/DocumentPreview')) },

      /* Onboarding */
      {
        path: 'onboarding',
        lazy: lazyRoute(() => import('./lazy/OnBoardingForm')),
        children: [{ path: 'idd', lazy: lazyRoute(() => import('./lazy/OnBoardingFormIddScreen')) }],
      },

      /* Profile */
      {
        path: 'profile/:id',
        lazy: lazyRoute(() => import('./lazy/Profile')),
        children: [
          { index: true, lazy: lazyRoute(() => import('./lazy/ProfileProfileCard')) },
          { path: 'personal', lazy: lazyRoute(() => import('./lazy/ProfilePersonalInformationForm')) },
          { path: 'contacts', lazy: lazyRoute(() => import('./lazy/ProfileContactInformationForm')) },
          { path: 'employment', lazy: lazyRoute(() => import('./lazy/ProfileEmploymentInformationForm')) },
        ],
      },

      /* Requirements */
      {
        path: 'requirements',
        lazy: lazyRoute(() => import('./lazy/Requirements')),
        children: [
          { index: true, lazy: lazyRoute(() => import('./lazy/RequirementsRequirementsCard')) },
          { path: 'situation', lazy: lazyRoute(() => import('./lazy/RequirementsSituationForm')) },
          { path: 'property', lazy: lazyRoute(() => import('./lazy/RequirementsProperty')) },
          { path: 'preferences', lazy: lazyRoute(() => import('./lazy/RequirementsPreferencesForm')) },
          { path: 'conveyancer', lazy: lazyRoute(() => import('./lazy/RequirementsConveyancerForm')) },
          { path: 'other-properties', lazy: lazyRoute(() => import('./lazy/RequirementsOtherProperties')) },
          { path: 'committed-expenditure', lazy: lazyRoute(() => import('./lazy/RequirementsCommittedExpenditure')) },
          {
            path: 'discretionary-expenditure',
            lazy: lazyRoute(() => import('./lazy/RequirementsDiscretionaryExpenditure')),
          },
          { path: 'protection/advice', lazy: lazyRoute(() => import('./lazy/RequirementsProtectionForm')) },
        ],
      },

      /* Conveyancing */
      {
        path: 'conveyancing',
        children: [
          { path: 'quote', lazy: lazyRoute(() => import('./lazy/ConveyancingQuote')) },
          {
            path: 'fact-find',
            lazy: lazyRoute(() => import('./lazy/ConveyancingFactFind')),
            children: [
              { path: 'property', lazy: lazyRoute(() => import('./lazy/ConveyancingFactFindCurrentProperty')) },
              { path: 'new-property', lazy: lazyRoute(() => import('./lazy/ConveyancingFactFindNewProperty')) },
              { path: 'about', lazy: lazyRoute(() => import('./lazy/ConveyancingFactFindPersonalInfo')) },
              { path: 'mortgage', lazy: lazyRoute(() => import('./lazy/ConveyancingFactFindMortgageForm')) },
              { path: 'completed', lazy: lazyRoute(() => import('./lazy/ConveyancingFactFindCompleteSection')) },
            ],
          },
        ],
      },

      /* Credit */
      {
        path: 'credit',
        lazy: lazyRoute(() => import('./lazy/Credit')),
        children: [
          { path: 'overview', lazy: lazyRoute(() => import('./lazy/CreditCreditOverview')) },
          { path: 'disclosure', lazy: lazyRoute(() => import('./lazy/CreditCreditReportIdd')) },
          { path: 'security/terms-and-conditions', lazy: lazyRoute(() => import('./lazy/CreditCreditReportIddKba')) },
          { path: 'id-checks', lazy: lazyRoute(() => import('./lazy/CreditIdentity')) },
          { path: 'id-checks/result', lazy: lazyRoute(() => import('./lazy/CreditEidv')) },
          { path: 'security/questions', lazy: lazyRoute(() => import('./lazy/CreditKbaQuestions')) },
        ],
      },

      /* Properties */
      {
        path: 'properties',
        children: [
          { index: true, lazy: lazyRoute(() => import('./lazy/PropertyList')) },
          {
            path: 'property-info',
            children: [
              { index: true, lazy: lazyRoute(() => import('./lazy/PropertyInfo')) },
              { path: 'epc', lazy: lazyRoute(() => import('./lazy/PropertyInfoEPC')) },
            ],
          },
          {
            path: 'property-info/:propertyId',
            children: [
              { index: true, lazy: lazyRoute(() => import('./lazy/PropertyInfo')) },
              { path: 'epc', lazy: lazyRoute(() => import('./lazy/PropertyInfoEPC')) },
            ],
          },
        ],
      },

      /* Homebuyers */
      { path: 'homebuyers-checklist', lazy: lazyRoute(() => import('./lazy/CheckListDashboard')) },

      /* Credit details */
      { path: 'credit-details', lazy: lazyRoute(() => import('./lazy/CreditDetails')) },

      /* Surveying */
      { path: 'surveying', lazy: lazyRoute(() => import('./lazy/Surveying')) },

      /* Wills */
      { path: 'wills', lazy: lazyRoute(() => import('./lazy/Wills')) },

      /* Dashboard3 */
      { path: 'cases/:id', children: [{ path: 'dashboard', lazy: lazyRoute(() => import('./lazy/CasesDashboard')) }] },

      /* Go Cardless */

      { path: 'go-cardless-payment-return', lazy: lazyRoute(() => import('./lazy/PaymentComplete')) },
      { path: 'go-cardless-payment-rejected', lazy: lazyRoute(() => import('./lazy/PaymentFailed')) },

      /* Tradesmen */
      { path: 'tradesmen', lazy: lazyRoute(() => import('./lazy/Tradesmen')) },

      /* Settings */
      {
        path: 'portal-settings',
        lazy: lazyRoute(() => import('./lazy/Settings')),
        children: [
          { index: true, lazy: lazyRoute(() => import('./lazy/DataPreferences')) },
          { path: 'credit', lazy: lazyRoute(() => import('./lazy/CreditSettings')) },
          { path: 'preferences', lazy: lazyRoute(() => import('./lazy/DataPreferences')) },
        ],
      },

      /* Schedule appointment routes */
      { path: 'survey', lazy: lazyRoute(() => import('./lazy/Survey')) },
    ],
  },
])

const ClientPortalRouter = () => {
  return <RouterProvider router={browserRouter} future={{ v7_startTransition: true }} />
}

export default ClientPortalRouter
